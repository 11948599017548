<template>
  <ui-component-modal
    modalTitle="Edit invoice detail"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="save"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <ui-base-input-field label="Reference" v-model="reference" />
      <ui-base-date-field
        label="Issue date"
        v-model="issueDate"
        :openCalendarInModal="true"
      />
      <ui-base-date-field
        label="Due date"
        v-model="dueDate"
        :openCalendarInModal="true"
      />
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import invoiceProvider from '@/providers/invoice'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
      reference: '',
      issueDate: new Date(),
      dueDate: new Date(),
      mInvoice: null,
    }
  },

  computed: {
    ...mapState('invoiceStore', ['invoice']),
  },

  created() {
    this.mInvoice = JSON.parse(JSON.stringify(this.invoice))
    this.reference = this.mInvoice.Reference
    this.issueDate = this.getActivationDate(this.mInvoice.ActivationDate)
    this.dueDate = this.mInvoice.ExpirationDate
  },

  methods: {
    ...mapMutations('invoiceStore', ['setInvoice']),

    save() {
      let self = this
      if (!self.isSaving) {
        this.isSaving = true
        invoiceProvider.methods
          .saveInvoiceDetail(
            this.invoice.Id,
            this.reference,
            this.issueDate,
            this.dueDate
          )
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true

              self.setInvoice(response.data)

              setTimeout(() => {
                self.onClickCancel()
              }, 1500)
            }
          })
          .catch((error) => {
            ////console.log(error.response)
            // TODO: emit to toast for feedback to user
          })
      }
    },

    getActivationDate(dateString) {
      let date = new Date(
        this.$options.filters.parseIsoDateStringToDate(dateString)
      )
      if (Number(date.getFullYear()) === 1900) {
        date = new Date()
      }
      return date
    },
  },
}
</script>
